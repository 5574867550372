.footer {
  width: 100%;
  min-height: 60px;
  border-top: 1px solid #ededed;

  .footerWrapp {
    max-width: 1140px;
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;

    p {
      color: #bbbbbb;
      font-size: 12px;
      font-weight: 400;
      line-height: 28px;
    }
    .footerLinks {
      display: flex;
      @media (max-width: 1024px) {
        flex-wrap: wrap;
        padding: 10px 0 10px;
        justify-content: center;
        flex: 1;
        min-width: 100%;
      }
      a {
        color: #bbbbbb;
        font-size: 12px;
        font-weight: 400;
        margin-left: 18px;
        transition: .2s;
        &:hover {
          color: #fe382e;
          transition: .2s;
        }
        @media (max-width: 1024px) {
          padding: 10px 0;
          &:first-child {
            margin-left: 0;
          }
        }
      }

    }
  }
  @media (max-width: 1170px) {
    .footerWrapp {
      padding: 0 15px;
    }
  }
  @media (max-width: 1024px) {
    .footerWrapp {
      justify-content: center;
      flex-wrap: wrap;
      p {
        order: 1;
        padding-bottom: 10px;
      }
    }
  }
}
