body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Noto Sans", sans-serif;
}
body {
  overflow: hidden;
  min-width: 1024px;
  .headerAvailableHearts {
    display: none;
  }
  .headerIntallExt {
    display: flex;
  }
}
body.noLimitWidth {
  min-width: initial;
}
.has-ext {
  overflow: auto;
  .UploadExtPopup {
    display: none;
  }
  .headerAvailableHearts {
    display: flex;
  }
  .headerIntallExt {
    display: none;
  }
}
#root {
  height: 100%;
}
.App {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.main {
  margin-top: 60px;
  flex-grow: 1;
  background-color: #fdfdfd;
}

a {
  text-decoration: none;
}
h1 , p , h3 {
  margin: 0;
}

.rc-slider {
  .rc-slider-rail {
    background-color: #d3d3d3;
  }
  .rc-slider-track {
    background-color: #fe382e;
  }
  .rc-slider-handle {
    width: 16px;
    height: 16px;
    background-color: #e7241f;
    border: none;
    margin-top: -6px;

    &:active {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
